import React from "react"
import MainNav from "./mainnav"
import style from "./header.module.css"

const Header = ({menuLinks}) => (
  <header>
    <div className={style.masthead_info}>
      {/*<Link to="/">*/}
      {/*<img*/}
      {/*src="/logo.svg"*/}
      {/*width="366"*/}
      {/*height="374"*/}
      {/*alt={siteTitle}*/}
      {/*className={style.site_logo}*/}
      {/*/>*/}
      {/*<div className={style.site_title}>{siteTitle}</div>*/}
      {/*<div className={style.site_description}>{siteDescription}</div>*/}
      {/*</Link>*/}
    </div>
    <MainNav menuLinks={menuLinks} />
  </header>
)

export default Header
